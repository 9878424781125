@font-face {
    font-family: GilmerLight;
    src: url("../fonts/GilmerLight.otf") format("opentype");
}

@font-face {
    font-family: GilmerRegular;
    src: url("../fonts/GilmerRegular.otf") format("opentype");
}

@font-face {
    font-family: GilmerMedium;
    src: url("../fonts/GilmerMedium.otf") format("opentype");
}

@font-face {
    font-family: GilmerBold;
    src: url("../fonts/GilmerBold.otf") format("opentype");
}

@font-face {
    font-family: GilmerHeavy;
    src: url("../fonts/GilmerHeavy.otf") format("opentype");
}