/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/
button {
    background: white;
    border: 5px solid var(--black);
    border-radius: 40px;
    padding: 15px 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: GilmerRegular;
    > svg {
        width: 14px;
        height: 14px;
    }
    cursor: pointer;
    transition: .3s ease-in-out;
    &:hover {
        background: #13131313;
    }
}
@media all and (min-width: 1024px) and (max-width: 1280px) { 
    button {
        padding: 16px 32px;
        font-size: 14pt;
        > svg {
            width: 12px;
            height: 12px;
        }
    }    
 }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
    button {
        padding: 16px 32px;
        font-size: 14pt;
        > svg {
            width: 12px;
            height: 12px;
        }
    }
}
 
@media all and (min-width: 480px) and (max-width: 768px) {
    button {
        padding: 16px 32px;
        font-size: 14pt;
        > svg {
            width: 12px;
            height: 12px;
        }
    }
}
 
@media all and (max-width: 480px) { 
    button {
        padding: 16px 32px;
        font-size: 14pt;
        > svg {
            width: 12px;
            height: 12px;
        }
    }
 }