/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/

@property --mask {
    syntax: '<angle>';
    inherits: false;
    initial-value: 0deg;
  }
  
  .main {
    height: 100vh;
    display: flex;
    align-items: center;
    .coming-soon-container {
      max-height: 760px;
      height: -webkit-fill-available;
      flex: 1;
      margin: 160px 0px 160px 160px;
      background-color: rgba(255, 255, 255, 0.4);
      color: var(--black);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 5;
      .logo {
        width: 140px;
        object-fit: contain;
        margin-bottom: 100px;
      }
  
      .header {
        font-size: 80px;
        margin: 0;
        font-family: GilmerBold;
        margin-bottom: 30px;
      }
  
      .subheader {
        font-size: 18px;
        font-family: GilmerLight;
        margin-bottom: 50px;
        line-height: 34px;
      }
  
      .connect {
        font-size: 28px;
        font-family: GilmerBold;
        .social-icons {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          align-items: center;
          > li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            width: 32px;
            border-radius: 100%;
            background-color: #E7E7E7;
            margin-right: 20px;
            cursor: pointer;
            transition: .2s ease-in-out;
            &:hover {
              opacity: .75;
            }
          }
        }
      }
    }
  
    .background {
      flex: 2;
      height: 100vh;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 120px;
      &::after {
        content: '';
        position: absolute;
        width: 840px;
        height: 500px;
        border-top-left-radius: 250px;
        border-bottom-left-radius: 250px;
        filter: blur(40px);
        transition: .4s ease-in-out;
        background: repeating-conic-gradient(from var(--mask), #632BFF, #FF691B, #FD2D80, #30C8FF);
        animation: rotate-gradient 15s linear infinite;
      }
      .logo-mark {
        position: absolute;
      }
      .white-circle {
        position: absolute;
        z-index: 2;
      }
    }
  
    @keyframes rotate-gradient {
      0% {
        --mask: 0deg;
      }
  
      100% {
        --mask: 360deg;
      }
    }
  }
  
  @media all and (min-width: 768px) and (max-width: 1024px) {
    .main {
      .coming-soon-container {
        margin: 80px 40px;
        .logo {
          width: 120px;
          margin-bottom: 80px;
        }
        .header {
          font-size: 40pt;
          margin-bottom: 20px;
        }
        
        .subheader {
          font-size: 12pt;
          margin-bottom: 30px;
        }
  
        .connect {
          font-size: 18pt;
        }
      }
      .background {
        padding-left: 0px;
        margin-left: 40px;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 60px;
        &::after {
          content: '';
          position: absolute;
          width: 87%;
          height: 250px;
          filter: blur(20px);
          transition: .4s ease-in-out;
          background: repeating-conic-gradient(from var(--mask), #632BFF, #FF691B, #FD2D80, #30C8FF);
          animation: rotate-gradient 15s linear infinite;
        }
        .logo-mark {
          width: 100%;
          height: auto;
        }
        .white-circle {
          width: 82%;
        }
      }
    }
  }
  
  @media all and (min-width: 480px) and (max-width: 768px) {
    .main {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      .coming-soon-container {
        margin: 80px 40px;
        .hide-mobile {
          display: none;
        }
        .logo {
          width: 120px;
          margin-bottom: 80px;
        }
        .header {
          font-size: 40pt;
          margin-bottom: 20px;
        }
        
        .subheader {
          font-size: 12pt;
          margin-bottom: 30px;
        }
  
        .connect {
          font-size: 18pt;
        }
      }
      .background {
        padding-left: 0px;
        margin-left: 0px;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 60px;
        &::after {
          content: '';
          position: absolute;
          width: 78%;
          height: 380px;
          filter: blur(25px);
          transition: .4s ease-in-out;
          background: repeating-conic-gradient(from var(--mask), #632BFF, #FF691B, #FD2D80, #30C8FF);
          animation: rotate-gradient 15s linear infinite;
        }
        .logo-mark {
          width: 90%;
          height: auto;
          position: relative;
        }
        .white-circle {
          width: 74%;
        }
      }
    }
  }
  
  @media all and (max-width: 480px) {
    .main {
      flex-direction: column;
      flex-wrap: nowrap;
      .coming-soon-container {
        margin: 80px 40px;
        .hide-mobile {
          display: none;
        }
        .logo {
          width: 120px;
          margin-bottom: 80px;
        }
        .header {
          font-size: 40pt;
          margin-bottom: 20px;
        }
        
        .subheader {
          font-size: 12pt;
          margin-bottom: 30px;
        }
  
        .connect {
          font-size: 18pt;
        }
      }
      .background {
        margin-left: 40px;
        padding-left: 0px;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 40px;
        &::after {
          content: '';
          position: absolute;
          width: 85%;
          height: 200px;
          filter: blur(20px);
          transition: .4s ease-in-out;
          background: repeating-conic-gradient(from var(--mask), #632BFF, #FF691B, #FD2D80, #30C8FF);
          animation: rotate-gradient 15s linear infinite;
        }
        .logo-mark {
          width: 100%;
          height: auto;
          position: relative;
        }
        .white-circle {
          width: 82%;
        }
      }
    }
  }